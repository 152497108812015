import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store, select } from '@ngrx/store'
import { FileObject, User, UserRoles } from '@tradecafe/types/core'
import { DeepReadonly, compareBy } from '@tradecafe/types/utils'
import { identity, omit, pickBy } from 'lodash-es'
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, of, timer } from 'rxjs'
import { catchError, map, switchMap, take } from 'rxjs/operators'
import { AuthApiService } from 'src/api/auth'
import { FileApiService, FileUploaderService } from 'src/api/file'
import { UserApiService } from 'src/api/user'
import { ValidateApiService } from 'src/api/validate'
import { loadCountries, selectAllCountries } from 'src/app/store/countries'
import { createUserSuccess, selectAllUsers, updateUserSuccess } from 'src/app/store/users'
import { environment } from 'src/environments/environment'
import { GeoService } from 'src/pages/admin/settings/locations/location-form/geo.service'
import { UsersService, formatErrorMessage, isAtLeastAdmin } from 'src/services/data/users.service'
import { EMAIL_REGEX } from 'src/shared/regex/email'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'


export interface CompanyContactFormOptions {
  account: number,
  user?: User,
}

@Component({
  selector: 'tc-company-contact-form',
  styleUrl: './company-contact-form.component.scss',
  templateUrl: './company-contact-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyContactFormComponent implements OnInit, OnDestroy {
  constructor(
    private readonly AuthApi: AuthApiService,
    private readonly cd: ChangeDetectorRef,
    private readonly FileApi: FileApiService,
    private readonly FileUploader: FileUploaderService,
    private readonly Geo: GeoService,
    private readonly ValidateApi: ValidateApiService,
    private readonly store: Store,
    private readonly toaster: ToasterService,
    private readonly UserApi: UserApiService,
    private readonly Users: UsersService,
    private readonly dialogRef: MatDialogRef<CompanyContactFormComponent, User>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: CompanyContactFormOptions,
  ) {}

  // const
  protected readonly languageOptions = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'zh', name: 'Chinese (官话)' },
  ]
  protected readonly titleOptions = ['', 'Sr', 'Sra', 'Srta', 'Dr', 'Lic', 'Ing', 'Miss', 'Mr', 'Ms', 'Mrs']

  // options
  protected readonly userId = this.dialogData.user?.user_id
  protected readonly title = this.userId ? 'Update Contact' : 'Add New Contact'

  private readonly users$ = this.store.pipe(select(selectAllUsers), waitNotEmpty())

  // permissions
  protected readonly currentUserId = this.AuthApi.currentUser.user_id
  protected readonly isAdminManagerSuperuser = isAtLeastAdmin(this.AuthApi.currentUser)
  protected readonly isJuniorAdmin = this.AuthApi.currentUser.role === 'junior-administrator'

  // ref data
  protected readonly availableRoles = this.getAvailableRoles()
  protected readonly canEditUserRole = this.canEditUserRoleFn()
  protected readonly allTraders$ = this.users$.pipe(map(users => users.filter(u => u.role === 'trader')))
  protected readonly allLCs$ = this.users$.pipe(map(users => users.filter(u => u.role === 'logistics')))
  protected readonly phoneCodes = this.Geo.getCountryPhoneCodes().map(c =>
    ({
      code: `+${c.code}`,
      name: `+${c.code}`,
      hint: `${c.country.emoji} ${c.country.name}`,
      country: c.country,
    })).sort(compareBy(c => c.country.code))
  protected readonly countries$ = this.store.pipe(select(selectAllCountries), waitNotEmpty()).pipe(map(countries =>
    countries.sort(compareBy(c => c.name))))

  // form
  protected readonly userForm = this.buildUserForm(this.dialogData.user)
  protected readonly hasCell = !!this.dialogData.user?.attributes?.cell_phone
  protected readonly changePasswordCtrl = new FormControl('', Validators.minLength(8))
  protected hidePassword = true

  // file form
  @ViewChild('inputFile', { static: false }) inputFile: ElementRef<HTMLInputElement>
  protected readonly signatureFile$ = new ReplaySubject<FileObject>(1)
  protected blobFile: { blobUrl: string; name: string }

  // state
  protected readonly inProgress$ = new BehaviorSubject<'loading'|'save'|undefined>('loading')


  ngOnInit() {
    this.store.dispatch(loadCountries())

    const signatureFileId = this.dialogData.user?.attributes?.signature_file_id
    if (signatureFileId) {
      this.FileApi.get(signatureFileId).then(({data}) => {
        this.signatureFile$.next(data)
      })
    } else {
      this.signatureFile$.next(undefined)
    }

    combineLatest([this.signatureFile$, this.countries$]).pipe(take(1)).subscribe(() =>
      this.inProgress$.next(undefined))
  }

  ngOnDestroy(): void {
    if (this.blobFile) URL.revokeObjectURL(this.blobFile.blobUrl)
  }

  /**
   * Check if currently logged in user can modify role field
   *
   * @private
   * @returns boolean
   */
  private canEditUserRoleFn() {
    const { role } = this.AuthApi.currentUser
    const isTradeCafe = environment.tradecafeAccount === this.dialogData.account
    if (isTradeCafe) {
      const canCreateUsers = [UserRoles.superuser.id, UserRoles.manager.id, UserRoles.administrator.id, UserRoles['junior-administrator'].id].includes(role)
      if (!canCreateUsers) return false
    }
    // return true if user role is not yet defined (meaning we're creating new user)
    if (!this.dialogData.user?.role) return true
    // allow editing only if role is available for editing
    return !!this.availableRoles.find(r => r.id === this.dialogData.user?.role)
  }

  /**
   * - Managers can create users with any role.
   * - Administrators can create users with any role except “manager”
   * - *No other user can create users in the TradeCafe account.*
   * - All other users can create new users with roles “trader”, logistics, or
   *            “accounting” in accounts OTHER than the TradeCafe staff account.
   *
   * @private
   * @returns list of role objects (@see role.js)
   */
  private getAvailableRoles(): Array<typeof UserRoles[keyof typeof UserRoles]>{
    const { role } = this.AuthApi.currentUser
    const isTradeCafe = environment.tradecafeAccount === this.dialogData.account

    if (role === UserRoles.superuser.id) return Object.values(UserRoles)
    if (role === UserRoles.manager.id) return Object.values(omit(UserRoles, UserRoles.superuser.id))
    if (role === UserRoles.administrator.id || role === UserRoles['junior-administrator'].id)
      return Object.values(omit(UserRoles, 'manager', 'superuser'))
    if (isTradeCafe) return [] // no other users can create users in the TradeCafe account
    return [UserRoles.trader, UserRoles.logistics, UserRoles.accounting]
  }

  private buildUserForm(user?: DeepReadonly<User>) {
    let phoneCode = ''
    let phoneNumber = ''
    let whatsAppCode = ''
    let whatsAppNumber = ''
    let cellCode = ''
    let cellNumber = ''
    if (user?.primaryphone) {
      const { code, number } = this.Geo.splitPhoneNumber(user.primaryphone)
      phoneCode = code
      phoneNumber = number
    }

    if (user?.whatsapp) {
      const { code, number } = this.Geo.splitPhoneNumber(user.whatsapp)
      whatsAppCode = code
      whatsAppNumber = number
    }

    if (user?.attributes.cell_phone) {
      const { code, number } = this.Geo.splitPhoneNumber(user.attributes.cell_phone)
      cellCode = code
      cellNumber = number
    }

    const emailValidtorInstance = emailValidator(this.ValidateApi, control => control.value)
    const phoneValidatorInstance = phoneValidator(this.ValidateApi, control => {
      if (control === this.userForm.controls.phoneNumber) return `${this.userForm.controls.phoneCode.value}${control.value}`
      if (control === this.userForm.controls.cellNumber) return `${this.userForm.controls.cellCode.value}${control.value}`
      if (control === this.userForm.controls.whatsAppNumber) return `${this.userForm.controls.whatsAppCode.value}${control.value}`
      return control.value
    })

    return new FormGroup({
      // raw
      title: new FormControl(user?.attributes.title || ''),
      firstname: new FormControl(user?.firstname, Validators.required),
      lastname: new FormControl(user?.lastname, Validators.required),
      primaryemail: new FormControl(user?.primaryemail, [
        Validators.required,
        Validators.pattern(EMAIL_REGEX),
        uniqueEmailValidator(this.userId, this.users$),
      ], emailValidtorInstance),
      role: new FormControl(user?.role, Validators.required),
      managedTraders: new FormControl(user?.managedTraders || []),
      managedLogisticCoordinators: new FormControl(user?.managedLogisticCoordinators || []),
      addressStreet1: new FormControl(user?.address?.street1 || ''),
      addressCc: new FormControl(user?.address?.cc || ''),
      addressState: new FormControl(user?.address?.state || ''),
      addressCity: new FormControl(user?.address?.city || ''),
      addressPostal: new FormControl(user?.address?.postal || ''),
      phone_ext: new FormControl(user?.attributes?.phone_ext || ''),
      fax: new FormControl(user?.attributes?.fax || ''),
      fax_ext: new FormControl(user?.attributes?.fax_ext || ''),
      language: new FormControl(user?.language || 'en'),
      login_allowed: new FormControl(user?.login_allowed || false),
      allowLoginToAutomatedTrading: new FormControl(user?.allowLoginToAutomatedTrading || false),
      // calculated
      designated: new FormControl(user?.attributes?.designated === '1'), // TODO: WA-1052 remove single quotes after SER-369
      agent: new FormControl(user?.attributes?.agent === '1'), // TODO: WA-1052 remove single quotes after SER-369
      // parsed
      phoneCode: new FormControl(phoneCode, Validators.required),
      phoneNumber: new FormControl(phoneNumber, Validators.required, phoneValidatorInstance),
      whatsAppCode: new FormControl(whatsAppCode),
      whatsAppNumber: new FormControl(whatsAppNumber, [], phoneValidatorInstance),
      cellCode: new FormControl(cellCode, this.hasCell ? Validators.required : []),
      cellNumber: new FormControl(cellNumber, this.hasCell ? Validators.required : [], phoneValidatorInstance),
    })
  }

  private async readUserForm(): Promise<User> {
    const userForm = this.userForm.getRawValue()

    const [countries, signatureFile] = await combineLatest([this.countries$, this.signatureFile$]).pipe(take(1)).toPromise()
    const countryObj = countries.find(c => c.code === userForm.addressCc)
    const country = countryObj ? countryObj.name : this.dialogData.user?.address?.country

    return pickBy({
      ...this.dialogData.user,
      account: this.dialogData.account,
      allowLoginToAutomatedTrading: userForm.allowLoginToAutomatedTrading,
      firstname: userForm.firstname,
      language: userForm.language,
      lastname: userForm.lastname,
      login_allowed: userForm.login_allowed,
      managedLogisticCoordinators: userForm.managedLogisticCoordinators,
      managedTraders: userForm.managedTraders,
      primaryemail: userForm.primaryemail,
      primaryphone: `${userForm.phoneCode || ''}${userForm.phoneNumber || ''}`,
      role: userForm.role,
      user_id: this.userId,
      whatsapp: `${userForm.whatsAppCode || ''}${userForm.whatsAppNumber || ''}`,
      address: pickBy({
        ...this.dialogData.user?.address,
        street1: userForm.addressStreet1,
        country,
        cc: userForm.addressCc,
        state: userForm.addressState,
        city: userForm.addressCity,
        postal: userForm.addressPostal,
      }, identity) as User['address'],
      attributes: pickBy({
        ...this.dialogData.user?.attributes,
        cell_phone: `${userForm.cellCode || ''}${userForm.cellNumber || ''}`,
        title: userForm.title,
        phone_ext: userForm.phone_ext,
        fax: userForm.fax,
        fax_ext: userForm.fax_ext,
        designated: userForm.designated ? '1' : '0',
        agent: userForm.agent ? '1' : '0',
        signature_file_id: signatureFile?.file_id,
      }, identity),
    }, x => x || x === 0 || x === false) as any as User
  }

  protected inputFileChanged() {
    const file = this.inputFile?.nativeElement.files[0]
    if (this.blobFile) URL.revokeObjectURL(this.blobFile.blobUrl)
    const blob = new Blob([file])
    this.blobFile = { blobUrl: URL.createObjectURL(blob), name: file.name }
    this.cd.markForCheck()
  }

  protected removeUserSignatureFile() {
    if(this.inputFile){
      this.inputFile.nativeElement.value = ''
    }
    if (this.blobFile) {
      URL.revokeObjectURL(this.blobFile.blobUrl)
      this.blobFile = undefined
    }
    this.signatureFile$.next(undefined)
    // TODO: remove file from s3?
  }

  protected updateCellNumber(sameAsPhone: MatCheckboxChange) {
    this.userForm.patchValue({
      cellCode: sameAsPhone ? this.userForm.controls.phoneCode.value : null,
      cellNumber: sameAsPhone ? this.userForm.controls.phoneNumber.value : null,
    })
  }

  protected updateWhatsAppNumber(sameAsPhone: MatCheckboxChange) {
    this.userForm.patchValue({
      whatsAppCode: sameAsPhone ? this.userForm.controls.phoneCode.value : null,
      whatsAppNumber: sameAsPhone ? this.userForm.controls.phoneNumber.value : null,
    })
  }

  protected checkForErrorsIn(formControl: AbstractControl, name: string): string {
    if (formControl.hasError('required')) return `${name} is required`
    if (formControl.hasError('minLength')) return `${name} should have at least 8 characters`;
    if (formControl.hasError('pattern') || formControl.hasError('invalidEmail')) return `${name} is not valid`
    if (formControl.hasError('pattern') || formControl.hasError('invalidPhone')) return `${name} is not valid`
    if (formControl.hasError('uniqueEmail')) return `${name} already registered by another user`
    return ''
  }

  protected phoneCodesSearch(search: string) {
    search = search.toLowerCase()
    return (({ code, name, country }) =>
      `${code}`.startsWith(search) || name.startsWith(search) || country.name.toLowerCase().startsWith(search))
  }

  protected async save() {
    if (this.inProgress$.value) return
    this.userForm.markAllAsTouched()
    this.userForm.updateValueAndValidity()
    if (!this.userForm.valid) return

    this.inProgress$.next('save')

    try {
      await this.uploadSignatureFile()
      const user = await this.saveUser()
      await this.changePassword(user.user_id)

      this.store.dispatch(this.userId ? updateUserSuccess({ user }) : createUserSuccess({ user }))

      this.dialogRef.close(user)
    } finally {
      this.inProgress$.next(undefined)
    }
  }

  private async uploadSignatureFile() {
    const attachedFile = this.inputFile?.nativeElement.files[0]
    if (!attachedFile) return

    try {
      const file = await this.FileUploader.uploadFile(attachedFile, {
        visibility: 0,
      }).toPromise()
      this.signatureFile$.next(file)

    } catch (err) {
      console.error('Unable to upload invoice file', err)
      this.toaster.error('Unable to upload invoice file.', err)
      throw err
    }
  }

  /**
   * Create or update user, display notifications
   *
   * @private
   * @returns {User}
   */
  private async saveUser() {
    const user = await this.readUserForm()

    try {
      await this.ValidateApi.validatePhone(user.primaryphone).toPromise().catch((err) => {
        this.toaster.warning('Telephone is not valid.', err)
        throw new Error('Telephone is not valid.')
      })

      if (user.attributes.cell_phone) {
        await this.ValidateApi.validatePhone(user.attributes.cell_phone).toPromise().catch((err) => {
          this.toaster.warning('Cell phone is not valid.', err)
          throw new Error('Cell phone is not valid.')
        })
      }

      if (user.whatsapp) {
        await this.ValidateApi.validatePhone(user.whatsapp).toPromise().catch((err) => {
          this.toaster.warning('WhatsApp # is not valid.', err)
          throw new Error('WhatsApp # is not valid.')
        })
      }

      try {
        const result = this.userId
          ? await this.Users.update(user)
          : await this.Users.createFor(this.dialogData.account, user)

        this.toaster.success('Contact saved successfully.')

        return result
      } catch (err) {
        this.toaster.error('Unable to save contact. ', formatErrorMessage(err))
        throw err
      }
    } catch (err) {
      console.log('Unable to save contact.', err)
      throw err
    }
  }

  /**
   * Change user password, display notifications
   *
   * @param {string} userId
   */
  private async changePassword(userId: string) {
    const password = this.changePasswordCtrl.value
    if (!password) return
    try {
      await this.UserApi.setPasswordById(userId, password)
      this.toaster.success('Password updated successfully.')
    } catch (err) {
      console.error('Unable to update password.', err)
      this.toaster.error('Unable to update password.', err)
    }
  }
}

export function uniqueEmailValidator(userId: string, users$: Observable<DeepReadonly<User[]>>) {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    let users: DeepReadonly<User[]>
    users$.pipe(take(1)).subscribe(u => users = u)
    if (!users) return null
    const isEmailTaken = users.some(user => user.primaryemail === email && user.user_id !== userId);
    return isEmailTaken ? { 'uniqueEmail': true } : null;
  }
}

export function emailValidator(ValidateApi: ValidateApiService, getEmail: (control: AbstractControl) => string) {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.value) return of(null)
    return timer(1000).pipe( // throttle 1s
      switchMap(() => ValidateApi.validateEmail(getEmail(control), true).pipe(
        map(() => null),
        catchError(() => of({ 'invalidEmail': true })
      ))))
  }
}

export function phoneValidator(ValidateApi: ValidateApiService, getPhone: (control: AbstractControl) => string) {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.value) return of(null)
    return timer(1000).pipe( // throttle 1s
      switchMap(() => ValidateApi.validatePhone(getPhone(control)).pipe(
        map(() => null),
        catchError(() => of({ 'invalidPhone': true })
      ))))
  }
}
